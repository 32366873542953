<template>
   <div class="card cursor-pointer mb-0" @click="view">
      <div class="body text-center">
         <div class="circle">
            <img class="rounded-circle" :src="form.photo == null ? '' : form.photo" @error="imageError">
         </div>
         <h6 class="limitador mt-3 mb-2" >{{ form.nameDiscord }}</h6>
         <span class="limitador d-block" v-if="form.status == 'Approved'"><span class="color-theme mr-2">Status:</span><span class="text-success">{{ form.status}}</span></span>
         <span class="limitador d-block" v-else-if="form.status =='PendingApproval'"><span class="color-theme mr-2">Status:</span><span class="text-warning">{{ form.status }}</span></span>
         <span class="limitador d-block" v-else-if="form.status =='Denied'"><span class="color-theme mr-2">Status:</span><span class="text-danger">{{ form.status }}</span></span>
         <span class="limitador d-block" v-else><span class="color-theme mr-2">Status:</span><span class="text-danger">{{ 'Unknown' }}</span></span>
         <span class="limitador d-block"><span class="color-theme mr-2">Date:</span>{{ form.endDate == null ? 'Unknown' : new Date(form.endDate.year +'/'+ form.endDate.monthValue +'/'+ form.endDate.dayOfMonth).toLocaleDateString() }}</span>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Form',
   props: ['form'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      view : function () {
         this.$emit('view', this.form)
      }
   }
}

</script>

<style scoped>

img {
   width: 120px;
   height: 120px;
}

</style>