<template>
   <div class="col-12">
      <div class="card mb-1">
         <div class="body p-y-10">
            <h6 class="mt-0 mb-1">{{ answer.question }}</h6>
            <p class="my-0">
               <i class="fal fa-check text-success mr-2" v-if="answer.correct"></i>
               <i class="fal fa-times text-danger font-16 mr-2" v-else></i>
               <span>{{ answer.answer }}</span>
            </p>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Answer',
   props: ['answer']
}

</script>