<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" :class="pesquisa.resultado.length > 0 ? '' : 'empty'" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-12 col-sm-6 col-md-3 mb-3 px-1">
                     <label><i class="far fa-filter font-13 color-theme mr-1"></i> {{ $t("whitelistCompletedForms.search.type") }}</label>
                     <v-select class="rounded" title="Desktop" :options="$t('whitelistCompletedForms.search.types')" label="value" v-model="pesquisa.tipo" />
                     <select title="Mobile" class="form-control rounded" v-model="pesquisa.tipo">
                        <option v-for="(option, index) in $t('whitelistCompletedForms.search.types')" :key="index" :value="option">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-3 px-1" v-if="pesquisa.tipo.key == 'discord'">
                     <label><i class="fab fa-discord font-13 color-theme mr-1"></i> Discord</label>
                     <input type="text" class="form-control rounded" v-model="pesquisa.discord" maxlength="200" @keyup.enter="getCompletedForms" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 mb-3 px-1" v-else>
                     <label><i class="far fa-hashtag font-13 color-theme mr-1"></i>{{ $t("whitelistCompletedForms.search.playerId") }}</label>
                     <input type="text" class="form-control rounded" v-model="pesquisa.id" maxlength="200" @keyup.enter="getCompletedForms" />
                  </div>
                  <div class="col-12 col-sm-8 col-md-4 px-0">
                     <div class="row m-0">
                        <div class="col-6 mb-3 px-1">
                           <label><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("whitelistCompletedForms.search.initial") }}</label>
                           <datePicker title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataI"></datePicker>
                           <input title="Mobile" class="form-control rounded" type="date" v-model="pesquisa.dataI" />
                        </div>
                        <div class="col-6 mb-3 px-1">
                           <label><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("whitelistCompletedForms.search.final") }}</label>
                           <datePicker title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="pesquisa.dataF"></datePicker>
                           <input title="Mobile" class="form-control rounded" type="date" v-model="pesquisa.dataF" />
                        </div>
                     </div>
                  </div>
                  <div class="col-12 col-sm-4 col-md-1 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="getCompletedForms">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.resultado.length > 0">
         <div class="cards">
            <forms v-for="(form, index) in pesquisa.resultado" :key="index" :form="form" :index="index" @view="viewForm($event)" />
			</div>
		</div>
      <div class="col-12 my-5 text-center" v-else>{{ $t("whitelistCompletedForms.search.noRes") }}</div>

      <!-- modalForm -->
      <div class="modal fade" id="modalForm" tabindex="-1" aria-labelledby="modalFormLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalFormLabel">Form - #{{ modal.idPlayer }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-xl-6 px-1">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t("whitelistCompletedForms.search.formInfo") }}</h6>
                                    <hr>
                                 </div>
                                 <div class="col-6 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-check font-13 color-theme mr-1"></i> {{ $t("whitelistCompletedForms.answers.correct") }}</label>
                                    <input type="text" class="form-control" v-model="modal.correctAnswer" disabled />
                                 </div>
                                 <div class="col-6 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-hashtag font-13 color-theme mr-1"></i> {{ $t("whitelistCompletedForms.answers.playerId") }}</label>
                                    <input type="text" class="form-control" v-model="modal.idPlayer" disabled />
                                 </div>
                                 <div class="col-12 px-1 mb-3">
                                    <label class="mb-1 "><i class=" far fa-sync font-13 color-theme mr-1"></i> Status</label>
                                    <input class="form-control text-success" type="text" value="Approved" disabled v-if="modal.status == 'Approved'"/>
                                    <input class="form-control text-danger" type="text" value="Denied" disabled v-else-if="modal.status == 'Denied'"/>
                                    <button class="btn btn-primary w-100" v-else @click="aprovarUsuario(modal.id)">{{ $t("whitelistCompletedForms.answers.approve") }}</button>
                                 </div>
                                 <div class="col-6 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-calendar font-13 color-theme mr-1"></i> {{ $t("whitelistCompletedForms.search.initial") }}</label>
                                    <input type="text" class="form-control" v-model="modal.startDate" disabled />
                                 </div>
                                 <div class="col-6 px-1 mb-3">
                                    <label class="mb-1"><i class="far fa-calendar font-13 color-theme mr-1"></i> {{ $t("whitelistCompletedForms.search.final") }}</label>
                                    <input type="text" class="form-control" v-model="modal.endDate" disabled />
                                 </div>
                                 <div class="col-12 px-1">
                                    <label class="mb-1"><i class="fab fa-discord font-13 color-theme mr-1"></i> Discord</label>
                                    <input type="text" class="form-control" v-model="modal.nameDiscord" disabled />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="col-xl-6 px-1">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t("whitelistCompletedForms.answers.answers") }}</h6>
                                    <hr>
                                 </div>

                                 <div class="col-12 px-0">
                                    <div class="row m-0">
                                       <answer v-for="(answer, index) in modal.answers" :key="index" :answer="answer" :index="index" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("whitelistCompletedForms.answers.close") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import forms from '@/components/whitelist/Form.vue'
import answer from '@/components/whitelist/Answer.vue'

export default {
	name: 'CompletedForms',
   data: function () {
      return {
         ptBR: ptBR,
         pesquisa: {'tipo': this.$t('whitelistCompletedForms.search.types')[0], 'discord': null, 'id': null, 'dataI': null, 'dataF': null, 'resultado': []},
         modal: {},
      }
   },
   computed: {
      ... mapState({
         serverS: state => state.serverS,
         urlRest: state => state.urlRest
      })
   },
   components: {
      forms, answer
   },
   methods: {
      getCompletedForms : function () {
         let valor = '';
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];

			switch (this.pesquisa.tipo.key) {
				case 'discord':
					valor = this.pesquisa.discord
				break;
				default:
					valor = this.pesquisa.id
				break;
			}

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getcompletedwl',
            params: {
               idServer: this.serverS.id_s,
               type: this.pesquisa.tipo.key,
               value: valor,
               date1: this.pesquisa.dataI,
               date2: this.pesquisa.dataF
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      viewForm : function (form) {
         this.modal = JSON.parse(JSON.stringify(form));
         this.modal.startDate = this.modal.startDate.dayOfMonth +'/'+ this.modal.startDate.monthValue +'/'+ this.modal.startDate.year +' '+ this.modal.startDate.hour +':'+ this.modal.startDate.minute;
         this.modal.endDate = this.modal.endDate.dayOfMonth +'/'+ this.modal.endDate.monthValue +'/'+ this.modal.endDate.year +' '+ this.modal.endDate.hour +':'+ this.modal.endDate.minute;
         $("#modalForm").modal("show")
      },
      calcularData : function () {
         let agora = new Date();

         if (window.innerWidth < 767) {
            let zeroFill = n => {
               return ('0' + n).slice(-2);
            }

            this.pesquisa.dataI = agora.getFullYear() +'-'+ zeroFill((agora.getMonth() + 1)) +'-'+ zeroFill(agora.getDate());
            this.pesquisa.dataF = agora.getFullYear() +'-'+ zeroFill((agora.getMonth() + 1)) +'-'+ zeroFill(agora.getDate());

         } else {
            this.pesquisa.dataI = agora;
            this.pesquisa.dataF = agora;
         }
      },
      aprovarUsuario(id) {
         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/savestatuswhitelist',
            params: {
               id: id,
               status: 'Approved'
            }
         }).then(() => {
            $("#modalForm").modal("hide"),
            this.getCompletedForms()
         })
        
      },
   },
   mounted() {
      this.calcularData()

      $(window).resize(() => {
         this.calcularData()
      });

      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });
   }
}

</script>